var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-create"},[_c('div',{staticClass:"task-create-top"},[_c('p',{staticClass:"task-create__title"},[(!_vm.isEdit)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('create.title'))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('create.editTitle'))}})]),_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-input",class:{
        isError: _vm.isError,
      },attrs:{"type":"text","placeholder":_vm.$t('create.name')},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title", $event.target.value)}}}),_c('van-cell',{class:{
        isErrorCat: _vm.isErrorCat,
      },attrs:{"is-link":""},on:{"click":function($event){_vm.showPopover = true}}},[_vm._v(" "+_vm._s(_vm.category ? _vm.category.title : _vm.$t("create.category"))+" ")]),_c('textarea-autosize',{class:[
        'form-textarea',
        {
          isErrorDesc: _vm.isErrorDesc,
        },
      ],attrs:{"placeholder":_vm.$t('create.description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('div',{staticClass:"task-create-bottom"},[_c('van-button',{staticClass:"next-step-task",attrs:{"icon":"arrow","type":"primary","round":"","color":"#0F1113"},on:{"click":function($event){return _vm.goToNext()}}}),(_vm.isEdit)?_c('Button',{staticClass:"btn",attrs:{"button":{
        borderRadius: '100px',
        backgroundColor: '#FF3B30',
        color: '#fff',
        height: '54px',
        width: '54px',
        icon: 'trash.svg',
        iconPosition: 'left',
      }},nativeOn:{"click":function($event){return _vm.deleteDeal()}}}):_vm._e()],1),_c('van-popup',{staticClass:"popup-category-list",attrs:{"position":"bottom"},model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}},[_c('CategoryList',{on:{"close":function($event){_vm.showPopover = false},"setTaskCategory":_vm.setTaskCategory}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-create-step"},[_c('span',{staticClass:"active"}),_c('span'),_c('span')])
}]

export { render, staticRenderFns }