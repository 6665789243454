var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-create"},[_c('div',{staticClass:"task-create-top"},[_c('p',{staticClass:"task-create__title"},[(!_vm.isEdit)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('create.title'))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('create.editTitle'))}})]),_c('div',{staticClass:"task-create-step"},[_c('span',{on:{"click":function($event){return _vm.goToStep('')}}}),_c('span',{staticClass:"active"}),_c('span')]),_c('div',{staticClass:"form-date col",class:{
				isError: _vm.isErrorDurration,
				hidding: _vm.isInfinity
			}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.duration),expression:"form.duration"}],staticClass:"form-input",attrs:{"type":"text","readonly":_vm.readOnly,"placeholder":_vm.$t('create.duration')},domProps:{"value":(_vm.form.duration)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "duration", $event.target.value)}}}),_c('van-checkbox',{class:[
					'duration_infinity',
					{
						fullWith: _vm.isInfinity
					}
				],attrs:{"shape":"square"},model:{value:(_vm.isInfinity),callback:function ($$v) {_vm.isInfinity=$$v},expression:"isInfinity"}},[_vm._v(" "+_vm._s(_vm.$t("create.duration_infinity"))+" ")])],1),_c('div',{staticClass:"form-date",on:{"click":function($event){return _vm.chooseType(1)}}},[_c('div',{staticClass:"choose-date-type"},[_c('div',{staticClass:"choose-date-type-top"},[_c('div',{class:[
							'chsooen',
							{
								active: _vm.form.start_type == 1
							}
						]},[_c('span',{staticClass:"chsooen__circle"}),_c('p',{staticClass:"chsooen__start"},[_vm._v(_vm._s(_vm.$t("create.sync_start")))])]),_c('div',{staticClass:"calendar-date",on:{"click":function($event){return _vm.openCalendar()}}},[_c('img',{attrs:{"src":require("@/assets/images/calendar.svg"),"alt":"","srcset":""}}),(!_vm.form.start_date)?_c('p',{staticClass:"calendar__text"},[_vm._v(_vm._s(_vm.$t("create.choose_date")))]):_c('p',[_vm._v(_vm._s(_vm.$t("global.from"))+" "+_vm._s(_vm.convertDate(_vm.form.start_date)))])])]),(_vm.form.start_type == 1)?_c('p',[_vm._v(_vm._s(_vm.$t("create.sync_start_desc")))]):_vm._e()])]),_c('div',{staticClass:"form-date",on:{"click":function($event){return _vm.chooseType(0)}}},[_c('div',{staticClass:"choose-date-type"},[_c('div',{staticClass:"choose-date-type-top"},[_c('div',{class:[
							'chsooen',
							{
								active: _vm.form.start_type == 0
							}
						]},[_c('span',{staticClass:"chsooen__circle"}),_c('p',{staticClass:"chsooen__start"},[_vm._v(_vm._s(_vm.$t("create.free_start")))])])]),(_vm.form.start_type == 0)?_c('p',[_vm._v(_vm._s(_vm.$t("create.free_start_desc")))]):_vm._e()])]),_c('van-cell',{attrs:{"is-link":""},on:{"click":function($event){_vm.isShowFraq = true}}},[_vm._v(" "+_vm._s(_vm.category ? _vm.category.title : _vm.$t("create.category"))+" ")])],1),_c('div',{staticClass:"task-create-bottom"},[_c('van-button',{staticClass:"next-step-task",attrs:{"icon":"arrow","type":"primary","round":"","color":"#0F1113"},on:{"click":function($event){return _vm.goToNext()}}}),(_vm.isEdit)?_c('Button',{staticClass:"btn",attrs:{"button":{
				borderRadius: '100px',
				backgroundColor: '#FF3B30',
				color: '#fff',
				height: '54px',
				width: '54px',
				icon: 'trash.svg',
				iconPosition: 'left'
			}},nativeOn:{"click":function($event){return _vm.deleteDeal()}}}):_vm._e()],1),_c('van-dialog',{staticClass:"van-dialog-calendar",attrs:{"overlay":false,"show-confirm-button":false},model:{value:(_vm.isShowCalendar),callback:function ($$v) {_vm.isShowCalendar=$$v},expression:"isShowCalendar"}},[_c('CalendarReport',{attrs:{"isShow":_vm.isShowCalendar},on:{"onClose":function($event){_vm.isShowCalendar = false},"onConfirm":_vm.onConfirm}})],1),_c('van-popup',{staticClass:"popup-category-list",attrs:{"position":"bottom"},model:{value:(_vm.isShowFraq),callback:function ($$v) {_vm.isShowFraq=$$v},expression:"isShowFraq"}},[_c('FreqList',{on:{"close":function($event){_vm.isShowFraq = false},"setTaskCategory":_vm.setTaskCategory}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }