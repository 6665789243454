<template>
	<div class="default-layout">
		<router-view />
		<van-popup v-model="showPopover" position="bottom" closeable class="popup-task-create">
			<component :is="step" v-if="showPopover" :prevData="form" @changeStep="changeStep" />
		</van-popup>

		<van-popup v-model="showPopoverDelete" position="bottom" class="popup-task-delete">
			<Delete v-if="showPopoverDelete" :prevData="form" @cancel="showPopoverDelete = false" />
		</van-popup>
	</div>
</template>

<script>
import TaskCreate from "@/components/Task/Create";
import TaskCreate1 from "@/components/Task/Create1";
import TaskCreate2 from "@/components/Task/Create2";
import Delete from "@/components/Task/Delete";
import { eventBus } from "@/main";
import { mapActions, mapGetters } from "vuex";
// import _ from "lodash";
import ApiDeals from "@/api/deals.js";
import moment from "moment";

export default {
	data() {
		return {
			showPopover: false,
			showPopoverDelete: false,
			step: "TaskCreate",
			form: {},
			today: new Date()
		};
	},
	computed: {
		...mapGetters("User", ["selfUser"])
	},
	watch: {
		showPopover() {
			if (!this.showPopover) {
				document.title = "vmeste.app";
				this.step = `TaskCreate`;
				this.form = {};
			}
		}
	},
	components: { TaskCreate, TaskCreate1, TaskCreate2, Delete },

	methods: {
		...mapActions("Category", ["getCategoryList"]),
		...mapActions("User", ["getSelfUser"]),
		changeStep(action) {
			Object.assign(this.form, action);
		},
		correctVh() {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", vh + "px");
		}
	},
	mounted() {
		this.correctVh();
		this.getCategoryList();
		this.getSelfUser();
		if (this.$route.query.openStep) {
			let step = this.$route.query.openStep;
			this.showPopover = true;
			if (step == 0) {
				this.step = `TaskCreate`;
			} else {
				step = step > 2 ? 2 : step;
				this.step = `TaskCreate${step}`;
			}

			ApiDeals.getDeal({
				id: this.$route.query.id,
				today: moment(this.today).format("YYYY-MM-DD")
			})
				.then((response) => {
					this.form = Object.assign(this.form, response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}

		eventBus.$on("addTask", () => {
			this.showPopover = true;
		});

		eventBus.$on("deleteTask", () => {
			this.showPopoverDelete = true;
		});

		eventBus.$on("openCreateForm", (data) => {
			this.showPopover = true;
		});

		eventBus.$on("createdTask", (data) => {
			this.showPopover = false;
		});
		eventBus.$on("changeStep", (data) => {
			this.step = `TaskCreate${data.step}`;
			this.form = Object.assign(this.form, data.form);
		});
	}
};
</script>

<style lang="scss" scoped>
.default-layout {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	overflow: hidden;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
}

.popup-task-delete {
	border-radius: 20px 20px 0px 0px;
}
</style>
