<template>
	<div class="task-create">
		<div class="task-create-top">
			<p class="task-create__title">
				<span v-html="$t('create.title')" v-if="!isEdit"></span>
				<span v-html="$t('create.editTitle')" v-else></span>
			</p>
			<div class="task-create-step">
				<span @click="goToStep('')"></span>
				<span class="active"></span>
				<span></span>
			</div>
			<div
				class="form-date col"
				:class="{
					isError: isErrorDurration,
					hidding: isInfinity
				}"
			>
				<input type="text" class="form-input" v-model="form.duration" :readonly="readOnly" :placeholder="$t('create.duration')" />
				<van-checkbox
					v-model="isInfinity"
					shape="square"
					:class="[
						'duration_infinity',
						{
							fullWith: isInfinity
						}
					]"
				>
					{{ $t("create.duration_infinity") }}
				</van-checkbox>
			</div>

			<div class="form-date" @click="chooseType(1)">
				<div class="choose-date-type">
					<div class="choose-date-type-top">
						<div
							:class="[
								'chsooen',
								{
									active: form.start_type == 1
								}
							]"
						>
							<span class="chsooen__circle"></span>
							<p class="chsooen__start">{{ $t("create.sync_start") }}</p>
						</div>
						<div class="calendar-date" @click="openCalendar()">
							<img src="@/assets/images/calendar.svg" alt="" srcset="" />
							<p v-if="!form.start_date" class="calendar__text">{{ $t("create.choose_date") }}</p>
							<p v-else>{{ $t("global.from") }} {{ convertDate(form.start_date) }}</p>
						</div>
					</div>
					<p v-if="form.start_type == 1">{{ $t("create.sync_start_desc") }}</p>
				</div>
			</div>

			<div class="form-date" @click="chooseType(0)">
				<div class="choose-date-type">
					<div class="choose-date-type-top">
						<div
							:class="[
								'chsooen',
								{
									active: form.start_type == 0
								}
							]"
						>
							<span class="chsooen__circle"></span>
							<p class="chsooen__start">{{ $t("create.free_start") }}</p>
						</div>
					</div>
					<p v-if="form.start_type == 0">{{ $t("create.free_start_desc") }}</p>
				</div>
			</div>
			<van-cell is-link @click="isShowFraq = true">
				{{ category ? category.title : $t("create.category") }}
			</van-cell>
		</div>
		<div class="task-create-bottom">
			<van-button icon="arrow" type="primary" round class="next-step-task" color="#0F1113" @click="goToNext()" />
			<Button
				v-if="isEdit"
				class="btn"
				@click.native="deleteDeal()"
				:button="{
					borderRadius: '100px',
					backgroundColor: '#FF3B30',
					color: '#fff',
					height: '54px',
					width: '54px',
					icon: 'trash.svg',
					iconPosition: 'left'
				}"
			/>
		</div>
		<!-- <van-calendar lang="RU" v-model="isShowCalendar" @confirm="onConfirm" :show-confirm="false" /> -->

		<van-dialog v-model="isShowCalendar" :overlay="false" :show-confirm-button="false" class="van-dialog-calendar"><CalendarReport :isShow="isShowCalendar" @onClose="isShowCalendar = false" @onConfirm="onConfirm" /></van-dialog>

		<van-popup v-model="isShowFraq" position="bottom" class="popup-category-list">
			<FreqList @close="isShowFraq = false" @setTaskCategory="setTaskCategory" />
		</van-popup>
	</div>
</template>

<script>
import { eventBus } from "@/main";
import FreqList from "@/components/Category/FreqList";
import ApiDeals from "@/api/deals";
import moment from "moment";
import Button from "@/components/Global/Button.vue";
import CalendarReport from "@/components/Global/CalendarReport";
export default {
	props: {
		prevData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			isErrorDurration: false,
			isInfinity: false,
			readOnly: false,
			isShowCalendar: false,
			isShowFraq: false,
			isEdit: true,
			category: {
				value: 0,
				title: this.$t("create.freq_everyday")
			},
			form: {
				duration: "",
				type: 0,
				freq: 0,
				start_type: 0,
				start_date: null // YYYY-MM-DD
			}
		};
	},
	watch: {
		isInfinity() {
			if (this.isInfinity) {
				this.readOnly = true;
			} else {
				this.readOnly = false;
			}
		},
		"form.start_date": {
			handler(val) {
				// do stuff
				this.isShowCalendar = false;
			}
			// deep: true,
		},
		prevData: {
			handler: function () {
				this.form = JSON.parse(JSON.stringify(this.prevData));
			},
			deep: true
		}
	},
	computed: {},
	components: {
		FreqList,
		Button,
		CalendarReport
	},
	methods: {
		goToStep(step) {
			eventBus.$emit("changeStep", {
				step: step
			});
		},
		deleteDeal() {
			eventBus.$emit("deleteTask", {
				form: this.form
			});
		},
		goToNext() {
			this.isErrorDurration = false;
			let err = 0;
			if (!this.form.duration && !this.isInfinity) {
				this.isErrorDurration = true;
				err++;
			}

			if (err == 0) {
				if (this.isInfinity) {
					this.form.duration = 0;
				}

				// return;
				const e = this.$t("create.edit"),
					c = this.$t("create.success");
				if (this.prevData.id) {
					ApiDeals.editDeals(this.form).then((responce) => {
						this.form.deal_id = responce.data.id;
						eventBus.$emit("changeStep", {
							step: 2,
							form: this.form
						});

						if (responce.err.length && responce.err[0] != 0) {
							this.$notify({
								type: "danger",
								message: responce.err_msg[0]
							});
						} else {
							this.$notify({
								type: "success",
								message: e
							});
						}
					});
				} else {
					ApiDeals.createDeals(Object.assign(this.form, this.prevData)).then((responce) => {
						if (responce.err.length && responce.err[0] != 0) {
							this.$notify({
								type: "danger",
								message: responce.err_msg[0]
							});
						} else {
							this.$notify({
								type: "success",
								message: c
							});
							setTimeout(() => {
								this.form.deal_id = responce.data.id;
								eventBus.$emit("changeStep", {
									step: 2,
									form: Object.assign(this.form, responce.data)
								});
							}, 500);
						}
					});
				}
			} else {
				// this.$notify(this.$t("error.allField"));
			}
		},
		onConfirm(date) {
			this.show = false;
			this.form.start_date = moment(date).format("YYYY-MM-DD");
		},
		chooseType(type = 0) {
			if (type == 0) {
				this.form.start_date = null;
			}
			if (type == 1) {
				this.openCalendar();
			}
			this.form.start_type = type;
		},
		openCalendar() {
			this.isShowCalendar = true;
			this.form.start_type = 1;
		},
		setTaskCategory(item) {
			this.form.type = 1;
			this.form.freq = item.value;
			if (item.value == 0) {
				this.form.type = 0;
				this.form.freq = 0;
			}
			this.category = item;
		},
		convertDate(date) {
			return moment(date).format("DD.MM.YY");
		}
	},
	mounted() {
		if (this.prevData.hasOwnProperty("id")) {
			this.isEdit = true;
			this.form = JSON.parse(JSON.stringify(this.prevData));
			this.$nextTick(() => {
				if (this.form.duration == 0) {
					this.isInfinity = true;
				}
			});
		} else {
			this.isEdit = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.duration_infinity {
	background: #f5f5f5;
	border-radius: 100px;
	padding: 10px 14px !important;
	max-width: 136px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	transition: all 0.35s ease-in-out;
	&.fullWith {
		max-width: 100%;
	}
}

.choose-date-type {
	&-top {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #0e1012;
		margin-bottom: 8px;
		.chsooen {
			display: flex;
			align-items: center;
			gap: 8px;
			opacity: 0.56;
			transition: all 0.25s;
			&.active {
				opacity: 1;
				.chsooen__circle {
					border: 10px solid #0e1012;
				}
			}
			&__circle {
				display: block;
				width: 24px;
				height: 24px;
				border-radius: 100%;
				transition: all 0.25s;
				border: 2px solid #aea8a8;
				// opacity: 0.56;
			}
			&__start {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				// opacity: 0.56;
			}
		}
	}
	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	.calendar-date {
		display: flex;
		align-items: center;
		gap: 8px;
		&__text {
			color: #0e1012;
		}
	}
}
</style>
