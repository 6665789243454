<template>
  <div class="task-create">
    <div class="task-create-top">
      <p class="task-create__title">
        <span v-html="$t('create.title')" v-if="!isEdit"></span>
        <span v-html="$t('create.editTitle')" v-else></span>
      </p>
      <div class="task-create-step">
        <span class="active"></span>
        <span></span>
        <span></span>
      </div>
      <input
        type="text"
        class="form-input"
        :class="{
          isError,
        }"
        v-model="form.title"
        :placeholder="$t('create.name')"
      />

      <van-cell
        is-link
        @click="showPopover = true"
        :class="{
          isErrorCat,
        }"
      >
        {{ category ? category.title : $t("create.category") }}
      </van-cell>
      <textarea-autosize
        v-model="form.description"
        :class="[
          'form-textarea',
          {
            isErrorDesc,
          },
        ]"
        :placeholder="$t('create.description')"
      />
    </div>
    <div class="task-create-bottom">
      <van-button
        icon="arrow"
        type="primary"
        round
        class="next-step-task"
        color="#0F1113"
        @click="goToNext()"
      />

      <Button
        v-if="isEdit"
        class="btn"
        @click.native="deleteDeal()"
        :button="{
          borderRadius: '100px',
          backgroundColor: '#FF3B30',
          color: '#fff',
          height: '54px',
          width: '54px',
          icon: 'trash.svg',
          iconPosition: 'left',
        }"
      />
    </div>

    <van-popup
      v-model="showPopover"
      position="bottom"
      class="popup-category-list"
    >
      <CategoryList
        @close="showPopover = false"
        @setTaskCategory="setTaskCategory"
      />
    </van-popup>
  </div>
</template>

<script>
import CategoryList from "@/components/Category/List";
import { eventBus } from "@/main";
import { mapActions } from "vuex";
import Button from "@/components/Global/Button.vue";
export default {
  props: {
    prevData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPopover: false,
      isError: false,
      isErrorDesc: false,
      isErrorCat: false,
      isEdit: true,
      form: {
        title: "",
        id_category: "",
        description: "",
      },
      category: null,
    };
  },
  computed: {
    // ...mapGetters("Category", ["categoryList"]),
  },
  watch: {
    prevData: {
      handler: function () {
        this.form = JSON.parse(JSON.stringify(this.prevData));
      },
      deep: true,
    },
  },
  components: {
    CategoryList,
    Button,
  },
  methods: {
    ...mapActions("Deal", ["setDateCreate"]),
    setTaskCategory(item) {
      this.form.id_category = item.id;
      this.category = item;
    },
    goToNext() {
      this.isError = false;
      this.isErrorDesc = false;
      this.isErrorCat = false;
      let err = 0;
      if (!this.form.title) {
        this.isError = true;
        err++;
      }
      if (!this.form.description) {
        this.isErrorDesc = true;
        err++;
      }
      if (!this.form.id_category) {
        this.isErrorCat = true;
        err++;
      }
      if (err == 0) {
        eventBus.$emit("changeStep", {
          step: 1,
          form: this.form,
        });
      }
    },
    deleteDeal() {
      eventBus.$emit("deleteTask", {
        form: this.form,
      });
    },
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.prevData));

    if (this.prevData.hasOwnProperty("id")) {
      this.isEdit = true;
      this.category = this.prevData.category;
      this.form.id_category = this.prevData.category.id;
    } else {
      this.isEdit = false;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>