<template>
  <div class="category-list">
    <div class="category-list-top">
      <IconButton
        class="index-100"
        :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }"
        :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }"
        @click="$emit('close')"
      />
      <p>{{ $t("create.categoryListTitle") }}</p>
    </div>
    <div
      class="category-list__item"
      v-for="(item, key) in categoryList"
      :key="key"
      @click="getTasksByCategory(item)"
    >
      <span class="category-list__emoji"> {{ item.emoji }}</span>
      {{ item.title }}
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import IconButton from "@/components/Global/IconButton.vue";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Category", ["categoryList"]),
  },
  components: {
    IconButton,
  },
  methods: {
    ...mapActions("Category", ["getCategoryList", "setCategory"]),
    getTasksByCategory(item) {
      this.$emit("setTaskCategory", item);
      this.$emit("close");
    },
  },
  mounted() {
    this.getCategoryList();
  },
};
</script>


<style lang="scss" scoped>
.category-list {
  // max-width: calc(100% - 9px);
  // // max-width: calc(100% - 48px - 9px);
  width: 100%;
  max-height: calc(100vh - 32px);
  // display: flex;
  // align-items: center;
  overflow-y: auto;
  // &::-webkit-scrollbar {
  //     width: 0;
  //     display: none;
  // }
  &-top {
    padding: 16px 24px;
    padding-bottom: 0;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    p {
      width: 100%;
      text-align: center;
      color: #0e1012;
      font-family: "Prata";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }
  &__item {
    font-size: 16px;
    line-height: 19px;
    padding: 16px;
    font-weight: 400;
    text-align: left;
    color: #0e1012;

    margin: 0 14px;
    margin-bottom: 8px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;
    }
    &:hover {
      color: #111;
    }
    &.active {
      font-weight: 500;
      background: #111111;
      border-color: #111;
      color: #ffffff;
    }
  }
  &__emoji {
    padding-right: 15px;
  }
}
</style>