<template>
  <div class="task-create">
    <div class="task-create-top">
      <p class="task-create__title">
        <span v-html="$t('create.title')" v-if="!isEdit"></span>
        <span v-html="$t('create.editTitle')" v-else></span>
      </p>
      <div class="task-create-step">
        <span @click="goToStep('')"></span>
        <span @click="goToStep(1)"></span>
        <span class="active"></span>
      </div>
      <p class="text">{{ $t("create.connect_tg_description") }}</p>
      <div class="btn-tg" @click="setChanel()" v-if="!form.channel">
        {{ $t("create.connect_tg_chanel") }}
      </div>
      <div class="btn-tg" v-else>
        <span> <b>канал:</b> {{ prevData.channel }} </span>
        <div @click="removeChanel()" class="btn-tg__remove">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.88121 7.99963L11.5687 5.31838C11.6864 5.20069 11.7525 5.04107 11.7525 4.87463C11.7525 4.70819 11.6864 4.54857 11.5687 4.43088C11.451 4.31319 11.2914 4.24707 11.125 4.24707C10.9585 4.24707 10.7989 4.31319 10.6812 4.43088L7.99996 7.11838L5.31871 4.43088C5.20102 4.31319 5.0414 4.24707 4.87496 4.24707C4.70852 4.24707 4.5489 4.31319 4.43121 4.43088C4.31352 4.54857 4.2474 4.70819 4.2474 4.87463C4.2474 5.04107 4.31352 5.20069 4.43121 5.31838L7.11871 7.99963L4.43121 10.6809C4.37263 10.739 4.32614 10.8081 4.2944 10.8843C4.26267 10.9604 4.24634 11.0421 4.24634 11.1246C4.24634 11.2071 4.26267 11.2888 4.2944 11.365C4.32614 11.4412 4.37263 11.5103 4.43121 11.5684C4.48931 11.627 4.55844 11.6735 4.6346 11.7052C4.71076 11.7369 4.79245 11.7533 4.87496 11.7533C4.95747 11.7533 5.03916 11.7369 5.11532 11.7052C5.19148 11.6735 5.26061 11.627 5.31871 11.5684L7.99996 8.88088L10.6812 11.5684C10.7393 11.627 10.8084 11.6735 10.8846 11.7052C10.9608 11.7369 11.0425 11.7533 11.125 11.7533C11.2075 11.7533 11.2892 11.7369 11.3653 11.7052C11.4415 11.6735 11.5106 11.627 11.5687 11.5684C11.6273 11.5103 11.6738 11.4412 11.7055 11.365C11.7373 11.2888 11.7536 11.2071 11.7536 11.1246C11.7536 11.0421 11.7373 10.9604 11.7055 10.8843C11.6738 10.8081 11.6273 10.739 11.5687 10.6809L8.88121 7.99963Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      {{ form.chat }}
      <div class="btn-tg" @click="setChat()" v-if="!form.chat">
        {{ $t("create.connect_tg_chat") }}
      </div>

      <div class="btn-tg" @click="removeChat()" v-else>
        <span> <b>чат:</b> {{ prevData.chat }} </span>
        <div @click="removeChanel()" class="btn-tg__remove">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.88121 7.99963L11.5687 5.31838C11.6864 5.20069 11.7525 5.04107 11.7525 4.87463C11.7525 4.70819 11.6864 4.54857 11.5687 4.43088C11.451 4.31319 11.2914 4.24707 11.125 4.24707C10.9585 4.24707 10.7989 4.31319 10.6812 4.43088L7.99996 7.11838L5.31871 4.43088C5.20102 4.31319 5.0414 4.24707 4.87496 4.24707C4.70852 4.24707 4.5489 4.31319 4.43121 4.43088C4.31352 4.54857 4.2474 4.70819 4.2474 4.87463C4.2474 5.04107 4.31352 5.20069 4.43121 5.31838L7.11871 7.99963L4.43121 10.6809C4.37263 10.739 4.32614 10.8081 4.2944 10.8843C4.26267 10.9604 4.24634 11.0421 4.24634 11.1246C4.24634 11.2071 4.26267 11.2888 4.2944 11.365C4.32614 11.4412 4.37263 11.5103 4.43121 11.5684C4.48931 11.627 4.55844 11.6735 4.6346 11.7052C4.71076 11.7369 4.79245 11.7533 4.87496 11.7533C4.95747 11.7533 5.03916 11.7369 5.11532 11.7052C5.19148 11.6735 5.26061 11.627 5.31871 11.5684L7.99996 8.88088L10.6812 11.5684C10.7393 11.627 10.8084 11.6735 10.8846 11.7052C10.9608 11.7369 11.0425 11.7533 11.125 11.7533C11.2075 11.7533 11.2892 11.7369 11.3653 11.7052C11.4415 11.6735 11.5106 11.627 11.5687 11.5684C11.6273 11.5103 11.6738 11.4412 11.7055 11.365C11.7373 11.2888 11.7536 11.2071 11.7536 11.1246C11.7536 11.0421 11.7373 10.9604 11.7055 10.8843C11.6738 10.8081 11.6273 10.739 11.5687 10.6809L8.88121 7.99963Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- <van-popover
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
      class="deals-type"
    >
      <template #reference>
        <div class="form-date">
          {{ $t("create.free") }}
          <img src="@/assets/images/arrow_up.svg" alt="" srcset="" />
        </div>
      </template>

      <div class="deals-type">
        <div
          class="deals-type-item"
          v-for="(item, key) in $t('create.type_deals')"
          :key="key"
        >
          <p class="deals-type-item__title">{{ item.title }}</p>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </van-popover> -->

    <div class="task-create-bottom">
      <van-button
        icon="arrow"
        type="primary"
        round
        class="next-step-task"
        color="#0F1113"
        @click="goToNext()"
      />
    </div>

    <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import CategoryList from "@/components/Category/List";
import ApiDeals from "@/api/deals";
import { eventBus } from "@/main";

export default {
  props: {
    prevData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPopover: false,
      isEdit: true,
      isLoading: false,
      actions: [
        { text: "Option 1" },
        { text: "Option 2" },
        { text: "Option 3" },
      ],
      form: {
        chat: null,
        channel: null,
      },
    };
  },
  watch: {
    prevData: {
      handler: function () {
        this.form = JSON.parse(JSON.stringify(this.prevData));
      },
      deep: true,
    },
  },
  components: {
    CategoryList,
  },
  methods: {
    removeChanel() {
      ApiDeals.editDeals({
        channel: null,
        id: this.prevData.id,
      });
      this.form.channel = null;
    },
    removeChat() {
      this.form.chat = null;
      ApiDeals.editDeals({
        chat: null,
        id: this.prevData.id,
      });
    },
    goToStep(step) {
      eventBus.$emit("changeStep", {
        step: step,
        form: this.prevData,
      });
    },
    setChanel() {
      const tg = window.Telegram.WebApp;
      ApiDeals.setChannel(this.prevData.deal_id).then((responce) => {
        tg.close();
      });
    },
    setChat() {
      const tg = window.Telegram.WebApp;
      ApiDeals.setChat(this.prevData.deal_id).then((responce) => {
        tg.close();
      });
    },
    onSelect(action) {
      console.log(action);
    },
    goToNext() {
      if (this.$route.path != `/deal/${this.prevData.id}`) {
        this.$router.push(`/deal/${this.prevData.id}`);
      }

      eventBus.$emit("createdTask");
    },
  },
  mounted() {
    if (this.prevData.hasOwnProperty("id")) {
      this.isEdit = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-tg {
  width: 100%;
  border: 1px solid rgba(14, 16, 18, 0.16);
  border-radius: 100px;
  padding: 12px 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;

  justify-content: center;
  &:has(.btn-tg__remove) {
    justify-content: space-between;
  }

  &.have {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &__remove {
    background: #aea8a8;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
}

.text {
  margin-bottom: 16px;
}
</style>